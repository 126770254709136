@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::after,
*::before {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  --customOrange: rgb(249 115 22 / 1);
  --customGray: rgb(102, 102, 102);

  transition: all 0.5s;
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

#root,
html {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.header--list>li+li {
  margin-left: 50px;
}

.header--list>li {
  position: relative;
  font-weight: 500;
  margin-bottom: 3px;
  margin-top: 3px;
  white-space: nowrap;
}

.header--label::before {
  content: '';
  position: absolute;
  pointer-events: none;
  width: 0%;
  height: 100%;
  color: var(--customOrange);
  overflow: hidden;
  transition: width 0.4s;
  -moz-transition: width 0.4s;
  -o-transition: width 0.4s;
}

.header--list>li:hover .header--label::before {
  content: attr(data-text);
  width: 100%;
}

.header--list>li::before {
  content: attr(data-text);
  position: absolute;
  bottom: -3px;
  transition: 0.4s width;
  -moz-transition: width 0.4s;
  -o-transition: width 0.4s;
  width: 0%;
  height: 2px;
  background-color: var(--customOrange);
}

.header--list>li:hover::before {
  width: 100%;
}

.page--header {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  background: rgba(255, 255, 255, 0.95);
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
}

.header--content {
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.page--header.sticky {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.page--header.sticky .header--content {
  padding-top: 16px;
  padding-bottom: 16px;
}

.site--content {
  margin-top: 150px;
}

.barToggler {
  transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  width: 30px;

  height: 21px;
  display: flex;
  align-items: center;
  opacity: 0;
  pointer-events: none;
}

.barToggler--button {
  width: 30px;
  height: 6px;
  border-radius: 5px;
  transition: all 0.6s ease-in-out;
  -moz-transition: all .6s ease-in-out;
  -o-transition: all .6s ease-in-out;
}

.barToggler--button::before,
.barToggler--button::after {
  content: '';
  position: absolute;
  width: 30px;
  height: 6px;
  border-radius: 5px;
  transition: all 0.6s ease-in-out;
  -moz-transition: all .6s ease-in-out;
  -o-transition: all .6s ease-in-out;
}

.barToggler--button::before {
  transform: translateY(-12px);
  -webkit-transform: translateY(-12px);
}

.barToggler--button::after {
  transform: translateY(12px);
  -webkit-transform: translateY(12px);
}

.barToggler.open .barToggler--button {
  transform: translateX(-50px);
  -webkit-transform: translateX(-50px);
  background: transparent;
}

.barToggler.open .barToggler--button::before {
  transform: rotate(45deg) translate(35px, -35px);
  -webkit-transform:rotate(45deg) translate(35px, -35px);
}

.barToggler.open .barToggler--button::after {
  transform: rotate(-45deg) translate(35px, 35px);
  -webkit-transform:rotate(-45deg) translate(35px, 35px);
}

.header--list {
  max-width: 1000px;
  transition: max-width 1s ease-in-out;
  -moz-transition: max-width 1s ease-in-out;
  -o-transition: max-width 1s ease-in-out;
  overflow-y: hidden;
}

.contact--card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
}

.contact--card:nth-child(even)>.card--image--holder {
  order: -1;
}

.text--contact--card {
  align-items: flex-start;
  justify-content: center;

  font-size: 18px;
}

.header--list.list--modal {
  max-width: none;
  overflow: hidden;
}

.header--list.list--modal>li::before {
  height: 3px;
}

.header--list.list--modal>li {
  margin-left: 30px;
  margin-right: auto;
  padding-top: 3px;
  padding-bottom: 3px;
}

.cards--holder>.contact--card:nth-child(n + 2) {
  margin-top: 50px;
}

.text--contact--card>p>i {
  font-weight: 600;
}

.card--image {
  width: 524px;
  height: 295px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.contact--countries {
  padding: 10px 20px;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  position: relative;
}

.contact--countries>.text--contact--card {
  align-items: center;
  font-weight: 400;
}

.contact--card:nth-child(even)>.text--contact--card {
  align-items: flex-end;
}

.text--countries--contact {
  font-weight: 600;
  color: var(--customOrange);
}

.text--countries--contact>div:nth-child(n + 2)>p {
  text-align: right;
}

.text--contact--card>.text--countries--contact>div>p {
  font-weight: 400;
}

.copy--link {
  position: relative;
}

.text--contact--card>p {
  display: flex;
  gap: 16px;
}

.footer--grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
}

@media screen and (max-width: 1100px) {
  .barToggler {
    opacity: 1;
    pointer-events: all;
  }

  .header--list>li:nth-child(-n) {
    margin-right: 0px;
  }

  .header--list {
    max-width: 0px;
  }

  .contact--card {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    place-items: center;
  }

  .res--change {
    width: 80%;
  }

  .contact--card:nth-child(2)>.card--image--holder {
    order: 1;
  }

  .contact--card:nth-child(n)>.text--contact--card {
    align-items: center;
  }

  .uns--title {
    text-align: center;
    margin-bottom: 32px;
  }

  .iveco--grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    text-align: center;
  }

  .iveco--grid>div {
    gap: 30px;
  }

  .iveco--image {
    height: 350px;
    width: 100%;
  }

  .start--page--grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
  }
}

@media screen and (max-width: 740px) {
  .text--chooser {
    width: 100%;
  }

  .text--displayer {
    height: auto;
  }

  .text--chooser--holder {
    flex-direction: column;
  }


}


.text--chooser--holder {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

@media screen and (max-width: 500px) {
  .text--chooser {
    gap: 0;
    justify-content: space-between;
  }
}

.copy--link::before {
  position: absolute;
  content: '';
  width: 0;
  height: 1px;
  background-color: white;
  transition: width 0.4s ease-in-out;
  -moz-transition: width 0.4s ease-in-out;
  -o-transition: width 0.4s ease-in-out;
  bottom: 0px;
}

.copy--link:hover::before {
  width: 100%;
}

.contact--container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  overflow-y: hidden;
}

.contact--details--holder>p {
  font-size: 15px;
  color: var(--customGray);
}

.outer--contact {
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.image--head--content {
  max-width: 100%;
  max-height: 100%;
}

.further--info>p {
  color: var(--customGray);
  font-size: 20px;
}

@media screen and (max-width: 800px) {
  .contact--container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
  }

  .basic--info--holder {
    flex-direction: column;
    align-items: center;
    gap: 12px;
    text-align: center;
  }

  .basic--info--title {
    text-align: center;
  }

  .grid--map {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    place-items: center;
    gap: 60px;
  }

  .further--info {
    text-align: center;
  }

  .further--info:nth-child(1) {
    margin-top: 30px;
  }

  .further--info:nth-child(n + 2) {
    margin-top: 50px;
  }

  .outer--contact {
    margin-top: 20px;
  }
}

.company--name--contact {
  transition: font-size 0.5s ease-in-out;
  -moz-transition: font-size 0.5s ease-in-out;
  -o-transition: font-size 0.5s ease-in-out;
}

@media screen and (max-width: 700px) {
  .company--name--contact {
    font-size: 36px;
  }
}

.special--input--span {
  --fontColor: var(--customOrange);
  --normalFontColor: #5f6368;
  --hoverColor: #161616;
  position: absolute;
  top: 16px;
  left: 15px;
  color: var(--normalFontColor);
  text-transform: capitalize;
  font-size: 18px;
  transition: top 0.2s, font-size 0.2s;
  -moz-transition: top 0.2s, font-size 0.2s;
  -o-transition: top 0.2s, font-size 0.2s;
  padding: 0 3px;
  pointer-events: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: white;
}

.not--capitalize {
  text-transform: none;
}

.special--input {
  --focusBorderColor: var(--customOrange);
  --normalBorderColor: #888c8f;
  --hoverColor: #161616;
  width: 100%;
  height: 100%;
  background: transparent;
  border: 1px solid var(--normalBorderColor);
  transition: none;
  -moz-transition: none;
  -o-transition: none;
  color: black;
  border-radius: 8px;
  padding: 0px 15px;
  font-size: 17px;
}

.special--input:hover {
  border: 1px solid var(--hoverColor);
}

.special--input:hover~.special--input--span,
.special--input:hover:not(:focus):valid~.special--input--span {
  color: var(--hoverColor);
}

.special--input:focus {
  border: 2px solid var(--focusBorderColor);
  outline: none;
}

.special--input:focus~.special--input--span {
  top: -8px;
  font-size: 13px;
  color: var(--fontColor);
}

.special--input:not(:focus):valid~.special--input--span {
  top: -8px;
  font-size: 13px;
  color: var(--normalFontColor);
}

.message--content {
  padding: 10px 15px;
  font-size: 16px;
  resize: none;
}

.ripple {
  --animationTime: 1s;
  position: absolute;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  pointer-events: none;
  border-radius: 50%;
  animation: animate var(--animationTime) linear infinite;
  z-index: 10000;
}

@keyframes animate {
  0% {
    width: 0px;
    height: 0px;
    opacity: 1;
  }

  100% {
    width: 500px;
    height: 500px;
    opacity: 0;
  }
}

.button--send {
  transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
}

.button--send:hover {
  color: white;
  background-color: rgb(255, 166, 0);
}

.error--label {
  opacity: 0;
  transition: opacity 0.5s ease-out;
  -moz-transition: opacity 0.5s ease-out;
  -o-transition: opacity 0.5s ease-out;
}

.error--label.active {
  opacity: 1;
}

@media screen and (max-width: 890px) {
  .names--input--container {
    flex-direction: column;
    gap: 0;
  }

  .sender--info--container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    gap: 0;
  }

  .sender--info--container>div {
    padding-top: 28px;
  }
}

.email--success {
  position: fixed;
  right: -130px;
  top: 200px;
  max-width: 130px;
  border: 1px solid transparent;
  transition: transform 0.4s ease-in-out;
  -o-transition: transform 0.4s ease-in-out;
  -moz-transition: transform 0.4s ease-in-out;
  border-radius: 4px 0 0 4px;
}

.email--success.active {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
}

.site--content--sm {
  margin-top: 84px;
}

.partner--card {
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: rgb(102 102 102 / 1);
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: 40px;
}

.partners--cards--holder {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

@media screen and (max-width: 1200px) {
  .partners--cards--holder {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
  }

  .partner--card:nth-child(even)>.image--partner--holder {
    order: 2;
  }

  .partner--card {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
  }
}

@media screen and (max-width: 950px) {
  .partner--card {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
  }

  .partner--card:nth-child(odd)>.image--partner--holder {
    order: 2;
  }

  .bottom--text--partnershaft,
  .partner--info {
    width: 95%;
  }

  .page--title {
    padding: 0;
    hyphens: auto;
  }
}

.partner--card:nth-child(even)>.image--partner--holder {
  order: 2;
}

.partner--image {
  height: 250px;
  width: 100%;
  border-radius: 10px;
}

.partner--card--text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.services--special--holder {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  place-items: center;
}

.services--card--holder {
  display: flex;
  gap: 20px;
}

.service--sections--holder {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 20px;
}

.service--sections--holder>section {
  text-align: center;
}

.contents--holder {
  display: contents;
}

.left--text--services {
  width: 90%;
}

@media screen and (max-width: 900px) {
  .services--special--holder {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
  }

  .middle--text--services {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
  }

  .right--text--services {
    grid-column-start: 3;
    grid-column-end: 5;
  }

  .left--text--services {
    grid-column-start: 1;
    grid-column-end: 3;

  }

  .service--sections--holder {
    grid-template-rows: 1fr auto;
    grid-template-columns: repeat(3, 1fr);
  }

  .service--sections--text--left {
    flex-direction: row;
  }

  .service--sections--text--left>p {
    width: 50%;
  }

  .left--text--section {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 2;
    margin-top: 30px;
  }

  .intersection--image--holder {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .services--card--holder {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr auto;
  }

  .image--handshake--holder {
    width: auto;
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .handshake--text>p {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .handshake--text {
    display: contents;
  }

  .contents--holder {
    display: flex;
    grid-column-start: 1;
    grid-column-end: 5;
  }

  .eco--section {
    flex-direction: column;
  }

  .eco--section>img {
    order: 2;
    align-self: center;
    width: 100%;
  }
}

.modal {
  width: 0;
  transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out;
  height: 100%;
  position: fixed;
  z-index: 201;
  top: 0;
  overflow: hidden;
  right: 0;
  background-color: white;
}

.modal.active {
  width: 300px;
}

#overlay {
  position: fixed;
  transition: 0.2s ease-in-out;
  -o-transition: .2s ease-in-out;
  -moz-transition: .2s ease-in-out;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(32, 33, 36, 0.6);
  pointer-events: none;
  z-index: 200;
}

#overlay.active {
  opacity: 1;
  pointer-events: all;
}

.page--main--image--holder {
  max-height: 600px;
  width: 100%;
}

.main--button {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 15%;
  width: auto;
}

.plant--image {
  object-position: 60% 0%;
  height: 100%;
}

@media screen and (min-width: 1800px) {
  .text--select--button{
    font-size: 32px;
    line-height: 44px;
  }

  .main--button{
    font-size: 32px;
    line-height: 44px;
    padding-left: 48px;
    padding-right: 48px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .button--boxes{
    font-size: 32px;
    line-height: 44px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .text--boxes{
    font-size: 64px;
  }

  .site--content--text,
  .table--text {
    font-size: 22px;
  }

  .image--handshake--holder>img {
    height: 600px;
  }

  .intersection--image--holder>img {
    height: 600px;
  }

  .eco--section>p {
    font-size: 30px;
    line-height: 40px;
  }

  .company--name--contact,
  .company--name--uns {
    font-size: 64px;
  }

  .basic--info--title {
    font-size: 32px;
    line-height: 40px;
  }

  .contact--details--holder>p {
    font-size: 18px;
    line-height: 28px;
  }

  .basic--info--icon--holder {
    padding: 16px;
  }

  .basic--info--icon {
    width: 36px;
    height: 36px;
  }

  .further--info--title {
    font-size: 32px;
  }

  .further--info>p {
    font-size: 18px;
  }

  .page--title {
    font-size: 36px;
    line-height: 44px;
  }

  .partner--card--text,
  .text--contact--card {
    font-size: 24px;
    line-height: 28px;
  }

  .double--arrow {
    width: 28px;
    height: 28px;
  }

  .card--image {
    width: 100%;
    height: 440px;
  }

  .title--uns--partners {
    font-size: 38px;
    line-height: 44px;
  }

  .text--countries--contact>div>p {
    font-size: 24px;
    line-height: 28px;
  }

  .page--main--image--holder {
    max-height: 900px;
  }
}

.lng--gas--card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  gap: 20px;
}



.image--handshake--holder>img,
.intersection--image--holder>img,
.plant--image--holder>img,
.image--partner--holder>img,
.plant--image, .img--boxes,
.truck--sky--holder>img,
.driver--image,
.iveco--image {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.lng--continued--text {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.table--section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.table--title {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: var(--customOrange);
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

@media screen and (max-width: 700px) {
  .send--inputs--holder {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 20px;
  }

  .start--main--image {
    height: 300px;
    object-position: 40% 50%;
  }

  .eco--main--image {
    height: 300px;
    object-position: 75% 50%;
  }

  .main--button {
    right: 5%;
  }
}

@media screen and (max-width: 800px) {
  .boxes--content--holder{
    flex-direction: column;
    gap: 20px;
  }
}

.boxes--content--holder{
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  padding-left: 10%;
  padding-right: 10%;
}

.button--boxes {
  transition: background-color .3s ease-in-out;
}

.button--boxes:hover {
  background-color: var(--customOrange);
}



@media screen and (max-width: 500px) {


  .text--contact--card {
    font-size: 16px;
  }

  .cards--holder {
    width: 95%;
  }

  .uns--title {
    width: 95%;
  }

  .text--countries--contact>div {
    font-size: 16px;
  }

  .contact--countries .text--contact--card {
    width: 100%;
  }

  .contact--footer {
    justify-content: start;
  }

  .footer--grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
    gap: 30px;
  }

  .image--handshake--holder {
    grid-column-end: 5;
  }

  .handshake--text>p:nth-child(1) {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  .intersection--image--holder {
    grid-column-end: 5;
  }

  .left--text--section {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 2;
  }

  .right--text--section {
    grid-row-start: 3;
    grid-column-start: 1;
    grid-column-end: 5;
  }

  .outer--contact {
    width: 95%;
  }

  .input--form {
    padding-left: 5px;
    padding-right: 5px;
  }

  .recaptcha--holder {
    width: 100%;
  }

  .recaptcha {
    width: 100%;
  }

  .modal.active {
    width: 70%;
  }

  .header--list.list--modal>li {
    margin-left: 20px;
  }

  .image--handshake--holder>img {
    object-position: 35% 50%;
  }

  .partnershaft--main--image, .kontakt--main--image {
    height: 220px;
  }

  .leistungen--main--image {
    height: 300px;
  }

  .services--special--holder {
    display: flex;
    flex-direction: column;
  }

  .services--card--holder {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .contents--holder {
    gap: 30px;
    flex-direction: column;
  }

  .service--sections--holder {
    display: flex;
    flex-direction: column;
  }

  .service--sections--holder>section:nth-child(2) {
    order: -1;
  }

  .service--sections--text--left {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .service--sections--text--left>p {
    width: 100%;
  }

  .plant--image--holder>img {
    height: 270px;
  }

  .googlem--holder>iframe {
    width: 100%;
    height: 300px;
  }

  .space--bar--uns,
  .iveco--holder,
  .uns--bottom--text {
    width: 95%;
  }


}

@media screen and (max-width: 1000px) and (min-width: 501px) {
  .footer--copy {
    font-size: 16px;
    width: 95%;
    padding-right: 10px;
    padding-left: 10px;
  }

  .googlem--holder {
    width: 100%;
  }

  .googlem--holder>iframe {
    width: 100%;
    height: 400px;
  }

  .footer--grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    margin-bottom: 40px;
    padding-bottom: 0px;
    gap: 20px;
  }

  .footer--grid>section:nth-child(1)>p{
    max-width: 80%;
  }
}


.table--section {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);
}

.truck--sky--holder>img {
  height: 45vh;
}

.table--grid, .table--grid2 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 30px;
}




@media screen and (max-width: 1000px) {
  .lng--gas--card {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
  }
}

.table--grid {
  border-radius: 10px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, auto);
  border: 2px solid var(--customGray);
}

.table--grid .site--list {
  margin-top: 0;
}

.table--row>div {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.site--list {
  list-style-type: circle;
  list-style-position: inside;
  margin-top: 20px;
}

.table--row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.table--grid>.table--row:nth-child(-n + 5) {
  border-bottom: 1px solid black;
}

.table--row>div:nth-child(-n + 3) {
  border-right: 1px solid black;
}

.table2--main--title {
  font-size: 24px;
  line-height: 32px;
  color: var(--customOrange);
  font-weight: 600;
}

.table--grid2 {
  display: none;
}

.table2--main--title {
  position: relative;
}

.sm--margin {
  margin-top: 10px;
}


.table2--main--title::before {
  content: '';
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: var(--customOrange);
  width: 100%;
}

@media screen and (max-width: 820px) {
  .lng--continued--text {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
  }

  .lng--continued {
    margin-bottom: 30px;
  }

  .section--title {
    font-size: 24px;
    line-height: 32px;
  }

  .table--grid2 {
    display: block;
  }

  .smaller--margin--top {
    margin-top: 20px;
  }

  .table--title {
    font-size: 16px;
    line-height: 22px;
  }

  .table--grid {
    display: none;
  }

  .table--section, .site--top {
    width: 95%;
  }

  .table--text, .quelle--label {
    font-size: 14px;
  }

  .plant--image {
    height: auto;
  }

  .table--grid2 .site--list {
    margin-top: 0;
  }
}

.text--select--button {
  background-color: var(--customOrange);
  padding: 14px 18px;
  height: 100%;
  position: relative;
}

.text--displayer.active>p {
  opacity: 0;
}

.text--displayer>p {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  font-size: 16px;
}
.text--select--button::before{
  transition: all .5s;
  outline: none;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.text--select--button.selected::before {
  outline: 5px solid white;
  outline-offset: -15px;
  color: var(--customOrange);
}

@media screen and (max-width: 800px) {
  .text--select--button.selected::before{
    outline: 5px solid white;
    outline-offset: -14px;
  }
  .text--select--button{
    padding-top: 17px;
    padding-bottom: 17px;
  }
}

@media screen and (min-width: 1800px) {
  .text--select--button.selected::before{
      outline: 8px solid white;
      outline-offset: -20px;
  }

  .text--displayer>p {
    font-size: 24px;
    line-height: 36px;
  }
}


@media screen and (max-width: 600px) {
  .desc--uns--partners {
    width: 100%;
  }
}